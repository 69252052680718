// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
//@import '~admin-lte/dist/css/adminlte.min.css';
//@import '~admin-lte/build/scss/adminlte';
@import "adminlte/adminlte";
@import '~admin-lte/plugins/select2/css/select2.min.css';
@import '~admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@import "~trumbowyg/dist/ui/sass/trumbowyg";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

//@import "~tempusdominus-bootstrap-4/build/css/tempusdominus-bootstrap-4.min";
@import "~jquery-datetimepicker-vishnu8742/build/jquery.datetimepicker.min";
@import "~vue-select/dist/vue-select.css";

.select2-container {
    width: 100% !important;
}

body {
    overflow-x: hidden;
    --blue: #{$primary};
}

.content-wrapper {
    height: auto;
}

.no-wrap {
    white-space: nowrap;
}

.settle-actions {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px;
    z-index: 9000;
    background: white;
    border-top: 1px solid #dee2e6;
    right: 0;
}

.pdf-embed {
    min-height: 400px;
}

.pdfobject-container {
    height: 30rem;
}

ul {
    margin: 0;

}

.wrapper {
    overflow-x: hidden;
}

.main-header {
    margin: 0 !important;

    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    background: white;
}

.control-sidebar {
    z-index: 1035;
}

.users-list-box {
    max-height: 200px;
    overflow-y: scroll;

    ul {
        list-style: none;
        padding: 0;

        li {
            &:hover {
                cursor: pointer;
                background: #aeaeae;
            }
        }
    }
}

.document-table {
    .list-group {
        li {
            &:first-child {
                background: #e1e1e1;
            }
        }
    }
}

.generate-body {
    label {
        width: 100%;
    }

    .cols-3 {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 15px;
        -moz-column-gap: 15px;
        column-gap: 15px;
    }

    .cols label, .cols div, .cols section {
        display: block;
        width: 100%;
        break-inside: avoid;
        page-break-inside: avoid;
        -webkit-column-break-inside: avoid;
    }

    input[type="text"], input[type="number"], input[type="password"], input[type="date"], input[type="file"], input[type="email"], select, textarea {
        @extend .form-control;
        //display: block;
        //padding: 0 10px;
        //margin: 3px 0 0 0;
        //width: 100%;
        //height: 50px;
        //border: 1px solid #ccc;
        //background: #fff;
        //font: 17px 'Roboto', sans-serif;
        //font-weight: 300;
        //line-height: 50px;
        //box-sizing: border-box;
    }
}

.import-sending {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background: rgba(0, 0, 0, 0.7);
    color: white;
}

.import-module {
    position: relative;

    &.sending {
        pointer-events: none;

        .import-sending {
            display: flex;
            justify-content: center;
            padding: 50px;

        }
    }
}

.import-table {
    th {
        white-space: nowrap;
    }

    tr {
        &.sending {
            background: orange !important;
        }

        &.error {
            background: red !important;
        }
    }

    //tr {
    //  display: none;
    //}
}

.login-box {
    width: 100%;
}

.message-body {
    img {
        max-width: 100%;
        height: auto;
    }
}

.message-card {
    .card-header {
        cursor: pointer;
    }

    .card-body {
        display: none;
    }
}

.account-dashboard-chart {
    width: 100%;
    height: 300px;
}

.is-loading {
    position: relative;

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        z-index: 100000;
    }
}

.search-form-actions {
    padding-bottom: 1rem;
    position: sticky;
    background: white;
    bottom: 0;
}

.app-logo {
    margin: auto;
    max-width: 250px;
    width: 100%;
}
